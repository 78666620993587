// 移动端头部组件
<template>
	<div class="phoneHeader">
		<div class="phone_menu" @click="showMenuList()">
			<img src="../assets/phone_menu.png" alt="" />
		</div>
		<div style="flex: 1; margin: 0 0.5rem;max-width: 9rem;height:100%">
			<router-link class="logo" v-if="this.$store.state.oid != 72" :to="'/home/' + this.$store.state.oid"><img
					:src="
            this.$store.state.baseHost +
            '/fs/download?storeKey=' +
            $store.state.orgLogo
          " :onerror="defaultLogoImg" /></router-link>
			<router-link class="logo" v-if="this.$store.state.oid == 72" :to="'/js/login/' + this.$store.state.oid"><img
					:src="
            this.$store.state.baseHost +
            '/fs/download?storeKey=' +
            $store.state.orgLogo
          " :onerror="defaultLogoImg" /></router-link>
		</div>
		<div class="phone_user_info_no_info" v-if="!this.$store.state.userInfo">
			<div class="nohas_info">
				<van-button type="info" style="height: 100%; width: 100%" :to="'/login/'+this.$store.state.oid">登录</van-button>
			</div>
		</div>
		<div class="phone_user_info" v-else>
			<div class="has_info" @click="controlShowPhoneUserInfo=true">
				<van-image round style="height: 100%; width: 100%" :src="
            this.$store.state.userInfo.memberPhotourl
              ? this.$store.state.baseHost +
                '/fs/download?storeKey=' +
                this.$store.state.userInfo.memberPhotourl
              : require('../assets/photo0.png')
          " />
			</div>
		</div>
		<!-- 弹出层左侧菜单导航 -->
		<van-popup v-model="controlShowMenuList" position="left">
			<div class="menu_list_wrap">
				<div v-bind:key="index" v-for="(item,index) in navbarData" class="navbar_wrap"
					:class="item.id==phoneCurrentId?'nav_current':''">
					<router-link :to="item.link">{{item.name}}</router-link>
				</div>
			</div>
		</van-popup>
		<!-- 		<van-popup v-model="controlShowPhoneUserInfo" position="top">
			<div class="user_info_popup_wrap">
              <div
                class="user_info_btn_item"
                @click="changePsw"
              >
                <img src="../assets/lock.png">
                <span>修改密码</span>
            </div>
            <div
              class="user_info_btn_item"
              @click="goCenter"
            >
              <img src="../assets/ucicon.png">
              <span>个人中心</span>
              </div>
              <div
                class="user_info_btn_item"
                @click="xsLogout"
              >
                <img src="../assets/out.png">
                <span>退出登录</span>
                </div>
                </div>
		</van-popup> -->
		<van-action-sheet v-model="controlShowPhoneUserInfo" :title="'您好，'+ $store.state.userInfo.memberName">
			<div class="content">
				<div class="user_info_popup_wrap">
              <div
                class="user_info_btn_item"
                @click="changePsw"
              >
                <img src="../assets/lock.png">
                <span>修改密码</span>
            </div>
            <div
              class="user_info_btn_item"
              @click="goCenter"
            >
              <img src="../assets/ucicon.png">
              <span>个人中心</span>
              </div>
              <div
                class="user_info_btn_item"
                @click="xsLogout"
              >
                <img src="../assets/out.png">
                <span>退出登录</span>
                </div>
                </div>
			</div>
		</van-action-sheet>
		<van-popup v-model="controlShowChangePsw" style="width:80%;">
			<div class="form_change_psw_wrap">
				<p class="change_psw_title">修改密码</p>
				<van-form @submit="savaPsw" style="">
					<van-field v-model="nowPasswrod" type="password" name="nowPasswrod" label="当前密码"
						placeholder="请输入当前密码" :rules="[{ required: true, message: '当前密码不能为空' }]" />
					<van-field v-model="newPassword" type="password" name="newPassword" label="新密码" placeholder="请输入新密码"
						:rules="[{ required: true, message: '新密码不能为空' }]" />
					<van-field v-model="confirmPassword" type="password" name="confirmPassword" label="确认密码"
						placeholder="请输入确认密码" :rules="[{ required: true, message: '确认密码不能为空' }]" />
					<div class="change_psw_wrap_btn_wrapper">
						<van-button round block type="default" native-type="button" style="margin-right:.2rem"
							@click="controlShowChangePsw =false">取消</van-button>
						<van-button round block type="info" style="margin-left:.2rem" native-type="submit">保存
						</van-button>
					</div>
				</van-form>
			</div>
		</van-popup>
	</div>

</template>

<script>
	// 这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）

	export default {
		props: {
			phoneCurrentId: {
				type: Number,
				default: 0
			}
		},
		components: {},
		// 定义属性
		data() {
			return {
				defaultLogoImg: 'this.src="' + require("../assets/logo.png") + '"',
				// 控制左侧弹出层菜单的显示与隐藏
				controlShowMenuList: false,
				// 控制用户信息弹出层的显示与隐藏
				controlShowPhoneUserInfo: false,
				// 控制修改密码的显示与隐藏
				controlShowChangePsw: false,
				navbarData: [{
						name: "首页",
						link: "/home/" + this.$store.state.oid,
						id: 0
					},
					{
						name: "课程广场",
						link: "/kechengguangchang",
						id: 5
					},
					{
						name: "大赛介绍",
						link: "/dasaijieshao",
						id: 1
					},
					{
						name: "在线报名",
						link: "/zaixianbaoming",
						id: 2
					},
					{
						name: "往期赛事",
						link: "/wangqisaishi_list",
						id: 3
					}
				],
				nowPasswrod: "",
				newPassword: "",
				confirmPassword: ""
			};
		},
		// 计算属性，会监听依赖属性值随之变化
		computed: {},
		// 监控data中的数据变化
		watch: {},
		// 方法集合
		methods: {
			showMenuList() {
				this.controlShowMenuList = true;
			},
			goCenter() {
				if (this.$store.state.userInfo.student) {
					this.$router.push({
						name: "student_profile"
					});
				} else {
					if (this.$store.state.oid == 72) {
						//江苏
						this.$router.push({
							name: "jspingshen_index"
						});
					} else {
						this.$router.push({
							name: "pingshen_index"
						});
					}
				}
			},
			xsLogout() {
				this.$dialog
					.confirm({
						title: "提示",
						message: "是否退出登录?"
						// overlay: false
						// theme: 'round-button',
					})
					.then(() => {
						// on confirm
						//(调用接口)
						this.$toast.loading({
							message: "退出中...",
							forbidClick: true
						});
						this.$post("/api/appuser/logout")
							.then(res => {
								this.$toast.clear();
								// console.log(res);
								if (res.result) {
									this.$store.commit("LOG_OUT");
									if (this.$store.state.oid == 72) {
										//江苏退出登录
										this.$router.push("/js/login/" + this.$store.state.oid);
									}else if (this.$store.state.oid == 110) { 
										//广州市职业技术教育研究院退出登录
										this.$router.push("/gzzj/login/" + this.$store.state.oid);
									} else {
										this.$router.push("/login/" + this.$store.state.oid);
									}
								} else {
									this.$toast.fail(res.resultMsg);
									console.log(res.resultMsg);
								}
							})
							.catch(err => {
								this.$toast.clear();
								console.error(err);
							});
					})
					.catch(() => {
						// on cancel
					});
			},
			savaPsw(value) {
				let _this = this;
				console.log(value);
				let {
					nowPasswrod,
					newPassword,
					confirmPassword
				} = value;
				if (newPassword != confirmPassword) {
					this.$toast.fail("两次输入的新密码不一致，请检查");
					return;
				}
				this.$toast.loading({
					message: "保存中...",
					forbidClick: true
				});
				this.$post("/api/appuser/updatePassword", {
						oldPassword: nowPasswrod,
						newPassword: newPassword
					})
					.then(res => {
						console.log(res);
						if (res.result) {
							this.$toast.clear();
							this.$toast.success(res.resultMsg);
							//这里执行登出
							this.$post("/api/appuser/logout").then(res => {
								console.log(res);
								if (res.result) {
									this.$store.commit("LOG_OUT");

									if (this.$store.state.oid == 72) {
										//江苏退出登录
										this.$router.push("/js/login/" + this.$store.state.oid);
									} else {
										this.$router.push("/login/" + this.$store.state.oid);
									}
								} else {
									this.$toast.fail(res.resultMsg);
									console.log(res.resultMsg);
								}
							});
						} else {
							this.$toast.fail(res.resultMsg);
							console.log(res.resultMsg);
						}
					})
					.catch(res => {
						this.$toast.clear();
						console.log(res);
					});
			},
			changePsw() {
				this.controlShowPhoneUserInfo = false;
				this.controlShowChangePsw = true;
			}
		},
		// 生命周期 - 创建完成（可以访问当前this实例）
		created() {
			
		},
		// 生命周期 - 挂载完成（可以访问DOM元素）
		mounted() {
			
		},
		beforeCreate() {}, // 生命周期 - 创建之前
		beforeMount() {}, // 生命周期 - 挂载之前
		beforeUpdate() {}, // 生命周期 - 更新之前
		updated() {}, // 生命周期 - 更新之后
		beforeDestroy() {}, // 生命周期 - 销毁之前
		destroyed() {}, // 生命周期 - 销毁完成
		activated() {
			this.controlShowPhoneUserInfo = false
		} // 如果页面有keep-alive缓存功能，这个函数会触发
	};
</script>

<style lang='less' scoped>
	.phoneHeader {
		display: flex;
		align-items: center;
		justify-content: space-between;
		box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
		padding: 0.2rem;
		position: fixed;
		top: 0;
		left: 0;
		height: 1.3rem;
		width: 100%;
		z-index: 2000;
		box-sizing: border-box;
		background: #fff;
	}

	.phone_menu {
		width: 0.8rem;
		height: 0.8rem;

		img {
			width: 100%;
			height: 100%;
		}
	}

	.logo {
		display: block;
		height: 100%;
		img {
			width: 100%;
			height: 100%;
			object-fit: contain;
		}
	}

	.phone_user_info_no_info {
		width: 60px;

		.nohas_info {
			height: 30px;
		}
	}

	.phone_user_info {
		width: 1.066rem;
		height: 1.066rem;

		.has_info {
			width: 100%;
			height: 100%;
		}
	}

	.menu_list_wrap {
		height: 100vh;
		background: #fff;
		// width: 200px;
		// padding: 0.5rem 0;
		overflow: hidden;
		min-width: 35vw;

		.navbar_wrap {
			padding: 0.5rem;

			// background: red;
			a {
				font-size: 0.4rem;
				color: #222;
				display: block;
				// margin-bottom: .4rem;
			}
		}
	}

	.nav_current {
		background: #f79238;

		a {
			color: #fff !important;
		}

		// font-weight: 700
	}

	.van-popup--left {
		overflow: hidden;
	}

	.user_info_popup_wrap {
		padding: 0.2rem 0.5rem;

		.user_info_btn_item {
			display: flex;
			align-items: center;
			padding: 0.4rem 0;
			border-bottom: 1px solid #eee;

			img {
				width: 0.6rem;
				height: 0.6rem;
			}

			span {
				font-size: 0.4rem;
				color: #333;
				margin-left: 0.2rem;
			}

			&:last-child {
				border-bottom: none;
			}
		}
	}

	.form_change_psw_wrap {
		padding: 0.5rem 0.5rem;
		// width: 100%;
		height: 100%;
	}

	.change_psw_wrap_btn_wrapper {
		margin-top: 0.5rem;
		display: flex;
	}

	.change_psw_title {
		font-size: 0.5rem;
		text-align: center;
		margin-bottom: 0.5rem;
		color: #303133;
	}
</style>
