import Vue from 'vue'
import router from './router'
import store from './store'
import { get, post } from './utils/http.js'
import axios from 'axios'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import 'element-ui/lib/theme-chalk/display.css';
// 引入淘宝移动端自适应插件
import 'lib-flexible'
import './plugins/vant'
import './plugins/ant-design'
import phoneHeader from './components/phoneHeader'
import phoneFooter from './components/phoneFooter'
import phoneftHeader from './components/phoneftHeader'
import phoneftFooter from './components/phoneftFooter'
import App from './App.vue'
import './assets/font/iconfont.css'
import './assets/iconfont.css'
import './assets/font_defenbangphone/iconfont.css'
// 视频播放插件
// import VideoPlayer from 'vue-video-player'
// import 'vue-video-player/src/custom-theme.css'
// import 'video.js/dist/video-js.css'
// Vue.use(VideoPlayer)
// import VueCoreVideoPlayer from 'vue-core-video-player'
// Vue.use(VueCoreVideoPlayer)
import VueDPlayer from "vue-dplayer";
import "vue-dplayer/dist/vue-dplayer.css";
import dataV from '@jiaminghi/data-view'
import scroll from 'vue-seamless-scroll'
import VueScroll from '@david-j/vue-j-scroll';
//引入动画
import 'animate.css';
Vue.use(VueScroll);
Vue.use(scroll)
//or you can set componentName default componentName is vue-seamless-scroll
Vue.use(scroll,{componentName: 'scroll-seamless'})

Vue.use(dataV)
Vue.use(VueDPlayer);

Vue.use(ElementUI);
Vue.component('phone-header',phoneHeader)
Vue.component('phone-footer',phoneFooter)
Vue.component('phoneft-header',phoneftHeader)
Vue.component('phoneft-footer',phoneftFooter)
import LyTab from 'ly-tab';
Vue.use(LyTab)
import {
  Checker
} from 'cube-ui'
Vue.use(Checker)
//定义全局变量
Vue.prototype.$get = get;
Vue.prototype.$post = post;
Vue.prototype.$axios = axios


Vue.config.productionTip = false

// JWT 用户权限校验，判断 TOKEN 是否在 localStorage 当中
// router.beforeEach((to, from, next) => {
//   // 获取 JWT Token
//   if (localStorage.getItem('JWT_TOKEN')) {
//     // 如果用户在login页面
//     if (to.path === "/login") {
//       next('/');
//     } else {
//       next();
//     }
//   } else {
//     if (to.path === '/login') {
//       next();
//     } else {
//       next({
//         path: "/login"
//       })
//     }
//   }
// });
router.beforeEach((to, from, next) => {
  /* 路由发生变化修改页面title */
  if (to.meta.title) {
    document.title = to.meta.title
  }
   next();
  //记录评审列表页滚动条高度
//   if(to.name == 'pingshen_detail' && from.name == 'pingshen_list'){
//    let scroll_top = 0;
//    if (document.documentElement && document.documentElement.scrollTop) {
//        scroll_top = document.documentElement.scrollTop;
//    }else if (document.body) {
//        scroll_top = document.body.scrollTop;
//    }
//    store.commit('saveScrollTop', scroll_top);
//  }
//  if((to.name != 'pingshen_list' && to.name != 'pingshen_detail')){
//    store.commit('clearScrollTop');
//  }


   //记录评审列表页滚动条高度
  //  if(to.name == 'jspingshen_detail' && from.name == 'jspingshen_list'){
  //   let scroll_top = 0;
  //   if (document.documentElement && document.documentElement.scrollTop) {
  //       scroll_top = document.documentElement.scrollTop;
  //   }else if (document.body) {
  //       scroll_top = document.body.scrollTop;
  //   }
  //   store.commit('saveScrollTop', scroll_top);
  // }
  // if((to.name != 'jspingshen_list' && to.name != 'jspingshen_detail')){
  //   store.commit('clearScrollTop');
  // }



  next()
})


new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
