import 'vant/lib/index.css';
import Vue from 'vue';
import {
	Form,
	Field,
	Button,
	Toast,
	Image as VanImage,
	Tab,
	Tabs,
	Popup,
	Dialog,
	ActionSheet,
	Empty,
	Tag,
	Divider,
	Lazyload,
	Search ,
	List,
	PullRefresh,
	Cell,
	Icon,
	Collapse,
	CollapseItem,
	DropdownMenu,
	DropdownItem,
	Sticky,
	NavBar 
} from 'vant';
Vue.use(Form);
Vue.use(Field);
Vue.use(Button);
Vue.use(Toast);
Vue.use(VanImage);
Vue.use(Tab);
Vue.use(Tabs);
Vue.use(Popup);
Vue.use(Dialog);
Vue.use(ActionSheet);
Vue.use(Empty);
Vue.use(Tag);
Vue.use(Divider);
Vue.use(Search);
Vue.use(List);
Vue.use(PullRefresh);
Vue.use(Cell);
Vue.use(Icon);
Vue.use(Collapse);
Vue.use(CollapseItem);
Vue.use(DropdownMenu);
Vue.use(DropdownItem);
Vue.use(Sticky);
Vue.use(NavBar);
Vue.use(Lazyload, {
	lazyComponent: true,
});



