<template>
  <div id="app">
    <!-- <div id="nav">
      <router-link to="/">Home</router-link> |
      <router-link to="/about">About</router-link>
    </div> -->
    <!-- <keep-alive include='Pingshen_list'> -->

    <router-view />
    <!-- </keep-alive> -->
  </div>
</template>
<script>
export default {
  mounted() {
    this.$router.afterEach((to, from, next) => {
      window.scrollTo(0, 0);
    });
  }
};
</script>
<style lang="less">
html {
  color: #000;
  background: #fff;
}

body,
div,
dl,
dt,
dd,
ul,
ol,
li,
pre,
code,
form,
fieldset,
legend,
input,
textarea,
p,
blockquote,
th,
td {
  margin: 0;
  padding: 0;
  font-size: 14px;
  font-family: "微软雅黑";
}
html,
body,
#app {
  width: 100%;
  height: 100%;
}
* {
  box-sizing: content-box;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}
input {
  outline: none;
}

fieldset,
img {
  border: 0;
}

address,
caption,
cite,
code,
dfn,
em,
th,
var {
  font-style: normal;
  font-weight: normal;
}

li {
  list-style: none;
}

caption,
th {
  text-align: left;
}

q:before,
q:after {
  content: "";
}

abbr,
acronym {
  border: 0;
  font-variant: normal;
}

sup {
  vertical-align: text-top;
}

sub {
  vertical-align: text-bottom;
}

s {
  text-decoration: none;
}

input,
textarea,
select {
  font-family: inherit;
  font-size: inherit;
  font-weight: inherit;
}

legend {
  color: #000;
}

/*包含以下四种的链接*/
a {
  text-decoration: none;
}
/*正常的未被访问过的链接*/
a:link {
  text-decoration: none;
}
/*已经访问过的链接*/
a:visited {
  text-decoration: none;
}
/*鼠标划过(停留)的链接*/
a:hover {
  text-decoration: none;
}
/* 正在点击的链接*/
a:active {
  text-decoration: none;
}

/* 海报图 */
.banner {
  width: 100%;
  height: auto;
  min-width: 1200px;
}
.banner img {
  width: 100%;
  display: block;
}

.btn:active {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
}
// 移动端除去头部主体区域padding
.phone_padding {
  overflow-x: hidden;
  padding: calc(100vh - (100vh - 1.3rem)) 0 0;
}
.van-dialog {
  z-index: 99999999 !important;
}
.van-toast {
  z-index: 999999991 !important;
}
.ant-drawer-content-wrapper {
  box-sizing: border-box;
  width: 100vw !important;
}
.ant-drawer-body {
  padding: 0;
}
// 移动端筛选弹出层
	.van-popup {
		z-index: 20211122 !important;
	}
  .van-overlay {
    z-index: 20211121 !important;
  }
  // .el-dialog__wrapper {
  //   z-index: 20211122 !important;
  // }
</style>
