<template>
  <div>
    <nav-bar v-bind:currentId="0"></nav-bar>
    <div class="main">
      <div class="banner">
        <img :src="this.$store.state.baseHost+'/fs/download?storeKey='+this.$store.state.orgCover" :onerror="defaultBannerImg"/>
      </div>
      <!-- <div class="mainBox">
        <div class="title">
          <div class="title-text">大赛阶段</div>
        </div>
        <div class="competition-process" id="competition-process">
          <div class="item">
            <div class="item-name">评审开始时间</div>
            <div class="item-time">2020年9月3日18:00</div>
            <div class="item-count">1</div>
          </div>
          <div class="item">
            <div class="item-name">评审结束时间</div>
            <div class="item-time">2020年9月5日18:00</div>
            <div class="item-count">2</div>
          </div>
          <div class="item">
                    <div class="item-name">结果公示时间</div>
                    <div class="item-time">2020年9月6日</div>
                    <div class="item-count">3</div>
                </div>
        </div>
      </div> -->
      <!-- <div class="mainBox">
            <div class="title">
                <div class="title-text">大赛介绍</div>
                <a href='#' class="more">更多&gt;</a>
            </div>
            <div class="introduce">
                <div class="left">
                    <div class="introduce-text" id="introduce-text">
                    </div>
                    <div class="title">
                        <div class="title-text">大赛公告</div>
                    </div>
                    <div class="pd4">
                        <a href="#" class="pd-item">参赛政策</a>
                        <a href="#" class="pd-item">参赛条件</a>
                        <a href="#" class="pd-item">赛程安排</a>
                        <a href="#" class="pd-item">奖品设置</a>
                    </div>
                </div>
                <div class="ad-pic">
                    <img src="${global.resurl}/assets/ad-pic.png"/>
                </div>
            </div>
        </div>
        <div class="ad-box">
            <img src="${global.resurl}/assets/ad.jpg"/>
        </div> -->
      <!-- <div class="mainBox">
        <div class="title">
          <div class="title-text">大赛公告</div>
          <a href="${global.pageurl}/dsgg/index.html" class="more">更多&gt;</a>
        </div>
        <div class="format" id="formatFirstBox">
          暂无数据
          <div class="left">
            <img src="../assets/ad-pic2.png" />
          </div>
          <div class="right">
            <div class="right-title">
              浙江大学举行 “建行杯” 第五届中国 “互联网+”
              大学生创新创业大赛动员会
            </div>
            <div class="right-text">
              10月8号下午，浙江大学举行 “建行杯” 第五届中国 “互联网+”
              大学生创新创业大赛动员会浙江大学举行 “建行杯” 第五届中国 “互联网+”
              大学生创新创业大赛动员会浙江大学举行 “建行杯” 第五届中国 “互联网+”
              大学生创新创业大赛动员会浙江大学举行 “建行杯” 第五届中国 “互联网+”
              大学生创新创业大赛动员会浙江大学举行 “建行杯” 第五届中国 “互联网+”
              大学生创新创业大赛动员会浙江大学举行 “建行杯” 第五届中国
              “互联网+”...<a href="#">&gt;&gt;查看详情</a>
            </div>
          </div>
        </div>

        <div class="format-list" id="formatOtherBox">
          <div class="item">
            <a href="#" class="item-title"
              >周江勇一行到浙江大学调研 “互联网+” 大赛筹备情况</a
            >
            <div class="item-time">2020-7-20</div>
          </div>
        </div>
      </div> -->
      <div class="mainBox">
        <div class="title">
          <div class="title-text">大赛列表</div>
          <!-- <a href="javascript:void(0)" class="more">更多&gt;</a> -->
        </div>
        <template v-if="listData.length">
          <div class="saishiList">
            <div class="saishi-item" v-for="(item,index) in listData" :key="index">
              <div class="left">
                <img :src="item.contestCoverUrl?$store.state.baseHost+'/fs/download?storeKey='+item.contestCoverUrl:require('../assets/ad-pic2.png')" :onerror="defaultImg"/>
              </div>
              <div class="right">
                <router-link class="right-type" :to="{'name':'dasaijieshao',params:{contestId: item.contestId}}" v-if="item.contestStatus==1">进行中</router-link>
                <router-link class="right-type yjs" to="/wangqisaishi_list" v-if="item.contestStatus==-1">已结束</router-link>
                <div class="right-title">
                  <router-link  :to="{'name':'dasaijieshao',params:{contestId: item.contestId}}" > {{item.contestName}}</router-link>
                </div>
                <div class="right-text">
                  {{item.contestRemark?item.contestRemark:'暂无赛事简介'}}
                </div>
                <div class="right-step">
                  大赛时间：{{item.contestStarttime.split(' ')[0]}} 至 {{item.contestEndtime.split(' ')[0]}}
                </div>
              </div>
            </div>
            <!-- <div class="saishi-item">
              <div class="left">
                <img src="../assets/ad-pic2.png" />
              </div>
              <div class="right">
                <div class="right-type yjs">已结束</div>
                <div class="right-title">
                  浙江大学举行 “建行杯” 第五届中国 “互联网+”
                  大学生创新创业大赛动员会
                </div>
                <div class="right-text">
                  10月8号下午，浙江大学举行 “建行杯” 第五届中国 “互联网+”
                  大学生创新创业大赛动员会浙江大学举行 “建行杯” 第五届中国+”
                  大学生创新创业大赛动员会浙江大学举行 “建行杯” 第五届中国+”
                  大学生创新创业大赛动员会浙江大学举行 “建行杯” 第五届中国+”
                  大学生创新创业大赛动员会浙江大学举行 “建行杯” 第五届中国第五届中国+”
                  大学生创新创业大赛动员会浙江大学举行 “建行杯” 第五届中国
                </div>
                <div class="right-step">
                  <el-steps :active="5">
                    <el-step title="报名"></el-step>
                    <el-step title="初赛"></el-step>
                    <el-step title="决赛"></el-step>
                    <el-step title="闭幕"></el-step>
                    <el-step title="公示"></el-step>
                  </el-steps>
                </div>
              </div>
            </div> -->
          </div>
        </template>
        <template v-else>
          <div class="no-saishiList">暂无赛事</div>
        </template>
      </div>
    </div>
    <bottom-bar></bottom-bar>
  </div>
</template>

<script>
// @ is an alias to /src
import navBar from "@/components/navBar";
import bottomBar from "@/components/bottomBar";
// import Toast from '@/components/toast/toast.js'

export default {
  name: "Home",
  components: {
    navBar,
    bottomBar
  },
  data() {
    return {
      listData: [],
      // orgCover: null,
      defaultImg: 'this.src="' + require("../assets/image404.gif") + '"',
      defaultBannerImg: 'this.src="' + require("../assets/banner.jpg") + '"',
    };
  },
  methods: {
    //获取机构下的大赛列表-进行中
    getDasaiList(){
      this.$post('/api/contest/list',{orgId:this.$store.state.oid}).then((res)=>{
        // console.log(res);
        if(res.result){
          if(res.resultData.length>0){
            this.listData = res.resultData;
            // console.log(this.listData);
          }
        }else{
          this.$message.error(res.resultMsg)
        }
      }).catch((res)=>{
        console.log(res)
        // this.$message.error(res);
      })
    },
   
  },
  created () {
    let oid = this.$route.params.oid;
    if(!oid){
      //如果未携带机构id，跳404
      this.$router.push("*");
      return;
    }else{
      this.$store.commit("updateOid", oid);
      this.$post('/api/common/getOrg',{orgId:oid}).then((res)=>{
        // console.log(res);
        if(res.result){
          this.$store.commit('updateOrg',res.resultData);
          if(res.resultData.orgStatus==1){
            location.href = 'http://www.cxcyedu.com';
          }
          // if(this.$store.state.orgData){
            // console.log(this.$store.state.orgData)
            // this.orgCover = this.$store.state.orgData.detail.orgCover;
          // }
        }else{
          // 清空机构信息
          this.$store.commit('clearOrg');
        }
        if(oid==111){
          this.$router.push({
            name: "kechengguangchangft"
          });
        }
      }).catch((res)=>{
        console.log(res);
        // this.$message.error(res);
      })
    }
    
  },
  mounted(){
    this.getDasaiList();
    //不同机构账户强制退出登录
    if(this.$store.state.userInfo){
      if(this.$store.state.userInfo.judeger){
         if(this.$route.params.oid != this.$store.state.userInfo.judeger.judgerOrgId){
          this.$store.commit("LOG_OUT");
        }
      }else{
         if(this.$route.params.oid != this.$store.state.userInfo.student.studentOrgId){
          this.$store.commit("LOG_OUT");
        }
      }
     
    }
  },
};
</script>
<style lang="less" scoped>
.main {
  width: 100%;
  height: auto;
}

.mainBox {
  width: 1200px;
  height: auto;
  margin: 0 auto;
  margin-top: 13px;
  background: #fff;
  padding: 35px 0 20px 0;
}
.title {
  width: 1132px;
  margin: 0 auto;
  height: 29px;
  border-bottom: 1px solid #c9c9c9;
  overflow: hidden;
  display: flex;
  justify-items: center;
  justify-content: space-between;
}
.title .title-text {
  font-size: 18px;
  color: #3ebdf6;
  border-bottom: 2px solid #3ebdf6;
  float: left;
  height: 27px;
  line-height: 27px;
  margin-left: 6px;
}
.title .more {
  text-decoration: none;
  color: #636363;
  font-size: 14px;
  height: 29px;
  line-height: 29px;
}

.competition-process {
  width: 1102px;
  margin: 0 auto;
  height: 110px;
  display: flex;
  justify-items: center;
  margin-top: 40px;
  position: relative;
}
.competition-process .item {
  flex: 1;
  width: 20%;
  position: relative;
  z-index: 2;
}
.competition-process .item .item-name {
  width: 132px;
  height: 36px;
  line-height: 36px;
  font-size: 18px;
  color: #fff;
  background: #f79238;
  margin: 0 auto;
  text-align: center;
}
.competition-process .item .item-time {
  height: 45px;
  line-height: 45px;
  font-size: 18px;
  color: #636363;
  text-align: center;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.competition-process .item .item-count {
  text-align: center;
  color: #fff;
  font-size: 12px;
  background: url("../assets/process-bg.png") center;
  background-size: contain;
  height: 22px;
  line-height: 22px;
  background-position: 50%;
  background-repeat: no-repeat;
}
.competition-process::after {
  content: "";
  width: 100%;
  height: 2px;
  background: #f79238;
  position: absolute;
  bottom: 17px;
  left: 0;
  z-index: 1;
}

/* 大赛介绍 */
.introduce {
  width: 1132px;
  margin: 25px auto;
  height: 360px;
  display: flex;
  justify-content: space-between;
}
.introduce .left {
  width: 680px;
  height: 360px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow: hidden;
}
.introduce .left .introduce-text {
  width: 680px;
  height: 145px;
  line-height: 35px;
  font-size: 18px;
  color: #000;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 4;
  overflow: hidden;
}
.introduce .left .pd4 {
  width: 100%;
  height: 117px;
  overflow: hidden;
}
.introduce .left .pd4 a {
  width: 140px;
  height: 117px;
  line-height: 117px;
  font-size: 18px;
  text-align: center;
  margin-left: 15px;
  border-radius: 8px;
  background: #f79238;
  color: #fff;
  display: block;
  float: left;
}
.introduce .ad-pic {
  width: 430px;
  height: 360px;
}
.introduce .ad-pic img {
  width: 430px;
  height: 360px;
}

/* 大赛广告 */
.ad-box {
  width: 1200px;
  height: 100px;
  margin: 20px auto;
}
.ad-box img {
  width: 100%;
  height: 100%;
}

/* 大赛赛制 */
.format {
  width: 1112px;
  height: auto;
  margin: 0 auto;
  padding: 0 10px;
  margin-top: 30px;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}
.format .left {
  width: 200px;
  height: 150px;
  border: 2px solid #c9c9c9;
  margin-right: 10px;
}
.format .left img {
  width: 100%;
  height: 100%;
  display: block;
}
.format .right {
  display: flex;
  align-items: flex-start;
  flex: 1;
  height: 160px;
  flex-direction: column;
}
.format .right-title {
  width: 800px;
  height: 25px;
  line-height: 25px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 18px;
  color: #3ebdf6;
  font-weight: bold;
  text-indent: 32px;
  margin-bottom: 20px;
}
.format .right-text {
  font-size: 16px;
  color: #000;
  text-indent: 32px;
  height: 120px;
  line-height: 30px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 4;
  overflow: hidden;
}
.format .right-text a {
  color: #3ebdf6;
}

.format-list {
  width: 1132px;
  height: auto;
  margin: 0 auto;
  margin-top: 20px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.format-list .item {
  width: 544px;
  height: 38px;
  line-height: 38px;
  border-bottom: 1px dashed #ccc;
  display: flex;
  justify-content: space-between;
  color: #000;
}
.format-list .item-title {
  text-indent: 12px;
  width: 405px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #000;
}
.format-list .item-title:hover{
  color: #3ebdf6;
}

.friend-link {
  width: 1132px;
  margin: 0 auto;
  padding: 30px 0 20px 0;
  display: flex;
  flex-wrap: wrap;
}
.friend-link a {
  color: #000;
  font-size: 16px;
  padding: 0 35px;
  height: 35px;
  line-height: 35px;
}

// 大赛列表
.saishiList{
  width: 1112px;
  height: auto;
  margin: 0 auto;
  overflow: hidden;
  .saishi-item{
    width: 100%;
    height: auto;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    padding: 30px 0;
    border-bottom: 1px solid #ccc;
    position: relative;
    // &:last-child{
    //   border-bottom: none;
    // }
    .left{
      width: 260px;
      height: 190px;
      img{width: 100%;height: 100%;display: block;}
    }
    .right{display: flex;flex: 1;flex-direction: column;align-items: flex-start;padding-left: 20px;height: 190px;
      .right-type{width: auto;height: 30px;line-height: 30px;padding: 0 10px;background: #289a28;color: #fff;position: absolute;right: 0;top: 30px;border-radius: 6px;cursor: pointer;
        &.yjs{background: grey;}
      }
      .right-title{width: 750px;
        height: 25px;
        line-height: 25px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        font-size: 18px;
        color: #3ebdf6;
        font-weight: bold;
        margin: 15px 0;}
      .right-step{width: 800px;}
      .right-text{font-size: 16px;color: #000;text-indent: 32px;height: 90px;line-height: 30px;display: -webkit-box;-webkit-box-orient: vertical;-webkit-line-clamp: 3;overflow: hidden;margin-bottom: 10px;}
    }
  }
}
.no-saishiList{padding: 30px 40px;font-size: 20px;}

</style>